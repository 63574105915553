/* src/App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 20px;
}

input {
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

h1, h2 {
  color: #333;
}
/* src/App.css */
.ads-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

